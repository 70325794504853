import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export default function initializeSentry() {
  Sentry.init({
    dsn: 'https://e9f454c531304d9db1eddb86baedc79c@o173130.ingest.sentry.io/1253396',
    allowUrls: [/identifyy/, /webpack/, /localhost/],
    integrations: [new Integrations.BrowserTracing()],
    environment: 'production',
  });
};
